import { createConsumer } from "@rails/actioncable";

const consumer = createConsumer("wss://www-open-shift.guruwalk.com/cable");

const createLocationChannel = (token) => {
  return consumer.subscriptions.create(
    { 
      channel: "LocationChannel",
      token: token
    }, 
    {
      connected() {
        console.log('Connected to LocationChannel');
      },

      disconnected() {
        console.log('Disconnected from LocationChannel');
      },

      received(data) {
        document.dispatchEvent(
          new CustomEvent("location:update", { detail: data })
        );
      },

      updateLocation(coordinates) {
        this.perform('update_location', {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        });
      }
    }
  );
};

export default createLocationChannel;
